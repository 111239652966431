<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('testing_and_tag_issue.approved_application') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <!-- <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{ $t('globalTrans.add_new') }}
            </b-button>
          </template> -->
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(application_date)="data">
                      <span class="capitalize">{{ getDate(data.item.created_at) }}</span>
                    </template>
                    <template v-slot:cell(seed_name)="data">
                      <span class="capitalize">{{ getSeedName(data.item.seed_name_id) }}</span>
                    </template>
                    <template v-slot:cell(seed_variety_name)="data">
                      <span class="capitalize">{{ getSeedVariety(data.item.seed_variety_id) }}</span>
                    </template>
                    <template v-slot:cell(seed_class_name)="data">
                      <span class="capitalize">{{ getSeedClass(data.item.seed_class_id) }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.application_status ? $t('certify_form.pending') : $t('certify_form.approved') }}
                    </template>
                    <template v-slot:cell(action)="data">
                        <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" title="View" @click="viewApplication(data.item)"><i class="ri-eye-line m-1"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="info"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <FormV :id="application.id"/>
    </b-modal>
  </b-container>
</template>

<script>
import FormV from './Application.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { getTagAndIssue } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { core } from '@/config/pluginInit'
import moment from 'moment'
export default {
    components: {
      FormV
    },
    mixins: [ModalBaseMasterList],
    data () {
        return {
            application: {},
            datas: [],
            info: {
                user_id: this.$store.state.Auth.authUser.id
            }
        }
    },
    computed: {
        formTitle () {
            return this.$t('testing_and_tag_issue.testing_tag_payment')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('testing_and_tag_issue.applicant_org_name'), class: 'text-left' },
                { label: this.$t('testing_and_tag_issue.applicationId'), class: 'text-left' },
                { label: this.$t('testing_and_tag_issue.created_at'), class: 'text-left' },
                { label: this.$t('testing_and_tag_issue.seed_name'), class: 'text-left' },
                { label: this.$t('testing_and_tag_issue.seed_variety_name'), class: 'text-left' },
                { label: this.$t('testing_and_tag_issue.seed_class_name'), class: 'text-left' },
                { label: this.$t('globalTrans.action'), class: 'text-left' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'applicant_org_name' },
                    { key: 'applicationId' },
                    { key: 'created_at' },
                    { key: 'seed_name' },
                    { key: 'seed_variety_name' },
                    { key: 'seed_class_name' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'applicant_org_name' },
                    { key: 'applicationId' },
                    { key: 'created_at' },
                    { key: 'seed_name' },
                    { key: 'seed_variety_name' },
                    { key: 'seed_class_name' },
                    { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    mounted () {
        core.index()
        this.loadData()
    },
    methods: {
        viewApplication (item) {
            this.application = item
        },
        getDate (date) {
            return moment(date).format('L')
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(agriResearchServiceBaseUrl, getTagAndIssue, this.info).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                    const allData = this.$store.state.list
                    this.datas = JSON.parse(JSON.stringify(allData))
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getSeedName (id) {
          if (id > 0) {
            const seed = this.$store.state.AgriResearch.commonObj.seedNameList.find(item => item.value === parseInt(id))
            if (this.$i18n.locale === 'en') {
              return seed.text_en
            } else {
              return seed.text_bn
            }
          }
        },
        getSeedVariety (id) {
          if (id > 0) {
            const seed = this.$store.state.AgriResearch.commonObj.seedVarietyList.find(item => item.value === parseInt(id))
            if (this.$i18n.locale === 'en') {
              return seed.text_en
            } else {
              return seed.text_bn
            }
          }
        },
        getSeedClass (id) {
          if (id > 0) {
            const seed = this.$store.state.AgriResearch.commonObj.seedClassNameList.find(item => item.value === parseInt(id))
            if (this.$i18n.locale === 'en') {
              return seed.text_en
            } else {
              return seed.text_bn
            }
          }
        },
        dataList (data) {
            const listData = data
            return listData
        }
    }
}
</script>

<style>

</style>
