<template>
    <b-overlay :show="loading">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(generalSubmit)" @reset.prevent="reset" >
                <b-row style="
                display: flex;
                flex-wrap: wrap;"
                >
                    <b-col lg="12" sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="text-dark"><i class="ri-edit-line"></i> {{ $t('certify_form.lot_details') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row >
                                    <b-col md="6">
                                        <ValidationProvider name="Lot Number" vid="lot_number_provided_by_company_or_org" rules="required">
                                        <b-form-group class="row" label-cols-sm="6" label-for="lot_number_provided_by_company_or_org" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.lot_number_provided_by_company_or_org')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                id="lot_number_provided_by_company_or_org"
                                                disabled
                                                v-model="data.lot_number_provided_by_company_or_org"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row style="
                        display: flex;
                        flex-wrap: wrap;"
                >
                    <b-col lg="12" sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="text-dark"><i class="ri-edit-line"></i> {{ $t('certify_form.tag_details') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row style="">
                                <b-col md="6">
                                    <ValidationProvider name="Seed Amount in Lot" vid="seed_amount" rules="required">
                                    <b-form-group class="row" label-cols-sm="6" label-for="seed_amount" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.seed_amount')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            plain
                                            id="seed_amount"
                                            disabled
                                            v-model="data.lot_no_provided"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6">
                                    <ValidationProvider name="Packet Size" vid="packet_size" rules="required">
                                    <b-form-group class="row" label-cols-sm="6" label-for="packet_size" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.packet_size')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            plain
                                            id="packet_size"
                                            disabled
                                            v-model="data.packet_size"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6">
                                    <ValidationProvider name="Amount of Seed per Certification Tag" vid="amount_seeds_per_certification_tag" rules="required">
                                    <b-form-group class="row" label-cols-sm="6" label-for="amount_seeds_per_certification_tag" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.seed_amount_per_tag')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            plain
                                            id="amount_seeds_per_certification_tag"
                                            disabled
                                            v-model="data.amount_seeds_per_certification_tag"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6">
                                    <ValidationProvider name="Total Demand of Certification Tag" vid="total_demand_certification_work" rules="required">
                                    <b-form-group class="row" label-cols-sm="6" label-for="total_demand_certification_work" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.total_demand')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            plain
                                            id="total_demand_certification_work"
                                            disabled
                                            v-model="data.total_demand_certification_work"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
    </b-overlay>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { storeGeneralInfo, getSingleTagAndIssue } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: ['id'],
  created () {
    this.loading = true
    // this.getGeneralInfo()
    const tmp = this.getGeneralInfo()
    this.data = tmp
  },
  data () {
    return {
        data: {
            id: '',
            amount_seeds_per_certification_tag: '',
            total_demand_certification_work: ''
        },
      loading: false,
    //   baseUrl: incentiveGrantServiceBaseUrl,
      tmpSignature: [],
      orgTypeList: [
        { value: 1, text: this.$t('certify_form.govt') },
        { value: 2, text: this.$t('certify_form.private') }
      ],
      maritalStatus: [
        { value: 1, text: this.$t('certify_form.single') },
        { value: 2, text: this.$t('certify_form.married') }
      ],
      errors: []
    }
  },
  watch: {
  },
  mounted () {
    core.index()
  },
  computed: {
  },
  methods: {
    async generalSubmit (isNext = 1) {
      this.loading = true
      let mainForm = {}
      if (!isNext) {
          this.data.draft = isNext
      }
      mainForm = { ...this.data, user_id: this.$store.state.Auth.authUser.id }

      var formData = new FormData()
      Object.keys(mainForm).map(key => {
        if (key === 'signature') {
            if (this.tmpSignature.name) {
                formData.append(key, this.tmpSignature)
            } else {
                formData.append(key, this.data[key])
            }
        } else {
          formData.append(key, mainForm[key])
        }
      })

      let result = null
      result = await RestApi.postData(agriResearchServiceBaseUrl, storeGeneralInfo, formData)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.loading = false
        return result
      } else {
        this.$toast.error({
          title: 'Error',
          message: this.$t('globalTrans.form_error_msg'),
          color: '#D6E09B'
        })
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    async getGeneralInfo () {
        const result = await RestApi.getData(agriResearchServiceBaseUrl, getSingleTagAndIssue, { id: 10 })
        if (result.success) {
            this.data = {}
        }
        this.loading = false
    },
    onSignatureChange (e) {
      this.tmpSignature = e.target.files[0]
      this.sig_src = URL.createObjectURL(e.target.files[0])
    }
  }
}
</script>
<style scoped>
 .form-control {
  padding: 0.375rem 0.75rem
 }
</style>
