<template>
  <b-container fluid>
    <b-row class="admissionFormMain">
      <b-col md="12">
        <form-wizard shape="circle" color="#66CC99" title="" subtitle="" next-button-text="Save And Next" back-button-text="Save And Next" finish-button-text="draft">
        <tab-content class="text-small" :title="$t('certify_form.testing_and_tag_issue')" icon="ri-home-4-line" :before-change="tabSubmitGeneral">
            <General :id="id" ref="social"/>
        </tab-content>
        <tab-content class="text-small" :title="$t('certify_form.payment')" icon="ri-home-4-line" :before-change="tabSubmitPayment">
            <Payment :id="id" ref="payment"/>
        </tab-content>
        <template slot="footer" scope="props">
            <div class=wizard-footer-left>
                <wizard-button class="btn btn-secondary rounded" @click.native="props.prevTab(), step--"  v-if="props.activeTabIndex > 0 && !props.isLastStep" :style="props.fillButtonStyle">Back</wizard-button>
            </div>
            <div class="wizard-footer-right">
                <wizard-button
                    type="submit"
                    v-if="!props.isLastStep"
                    @click.native="props.nextTab(), isNext = true"
                    class="btn btn-danger ml-1 mr-1 rounded"
                    title="Save and go to next!"
                    :style="props.fillButtonStyle"
                >Next</wizard-button>
                <wizard-button v-else
                    @click.native="isNext = true, tabSubmitAfterHarvesting()"
                    slot="finish"
                    type="submit"
                    class="btn btn-danger ml-1 mr-1 rounded"
                    title="Save and go to next!"
                    :style="props.fillButtonStyle">
                    Submit
                </wizard-button>
            </div>
        </template>
       </form-wizard>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { getStep } from '../../api/routes'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import researchTestingDropdown from '@/mixins/research-testing-dropdown'
import General from './General.vue'
import Payment from './Payment.vue'
import WizardButton from 'vue-form-wizard/src/components/WizardButton.vue'
export default {
  props: ['id'],
  // mixins: [researchTestingDropdown],
  components: {
    General,
    Payment,
    FormWizard,
    TabContent,
    WizardButton
  },
  data () {
      return {
          step: -1,
          isNext: false
      }
  },
  created () {
    core.index()
    if (this.id) {
    }
  },
  methods: {
    saveDraft () {
        if (this.step === 0) {
            this.tabSubmitGeneral()
        } else if (this.step === 1) {
            this.tabSubmitPayment()
        }
    },
    async getStep () {
        const result = await RestApi.getData(agriResearchServiceBaseUrl, getStep, { user_id: this.$store.state.Auth.authUser.id })
        if (result.success) {
            this.step = result.data.tab_position - 1
        } else {
            this.step++
        }
    },
    async tabSubmitGeneral () {
      // let result = null
      // result = await this.$refs.social.generalSubmit(this.isNext)
      if (this.isNext) {
        this.step = 1
        return true
      }
    },
    async tabSubmitPayment () {
      // let result = null
      // result = await this.$refs.payment.paymentSubmit(this.isNext)
      if (this.isNext) {
    //     this.$store.dispatch('ExternalUserIrrigation/setGrantApplicantDetails', result.data)
        this.step = 2
        return true
      }
    }
  }
}
</script>

<style lang="scss">
  .admissionFormMain{
    .wizard-icon-circle {
      width: 30px !important;
      height: 30px !important;
      font-size: 10px !important;
    }
    // .wizard-btn{
    //     display: none;
    // }
  }
 .vue-form-wizard .wizard-tab-content {
    min-height: 100px;
    padding: 0px;
 }
</style>
