<template>
    <b-overlay :show="loading">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(paymentSubmit)" @reset.prevent="reset" >
                <b-row style="
                display: flex;
                flex-wrap: wrap;"
                >
                    <b-col lg="12" sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="text-dark"><i class="ri-edit-line"></i> {{ $t('certify_form.payment') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-form-group>
                                    <template v-slot:label>
                                        {{ $t('certify_form.payment_method') }}
                                    </template>
                                    <b-form-radio-group
                                        v-model="data.payment_status"
                                        class="mr-5"
                                        :options="options"
                                        name="radio-inline"
                                    ></b-form-radio-group>
                                    <!-- <b-form-group name="radio-inline"  v-slot="{ ariaDescribedby }">
                                        <b-form-radio v-model="data.payment_status" :aria-describedby="ariaDescribedby" name="some-radios" value="1">Bank</b-form-radio>
                                        <b-form-radio v-model="data.payment_status" :aria-describedby="ariaDescribedby" name="some-radios" value="2">ekPay</b-form-radio>
                                    </b-form-group> -->
                                </b-form-group>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row style="
                    display: flex;
                    flex-wrap: wrap;"
                    v-show="data.payment_status === 1"
                >
                    <b-col lg="12" sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="text-dark"><i class="ri-edit-line"></i> {{ $t('certify_form.bank') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row>
                                    <b-col md="6" >
                                        <ValidationProvider name="Traeasury Challan Code" vid="treasury_challan_code" rules="required">
                                            <b-form-group class="row" label-cols-sm="6" label-for="treasury_challan_code" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('certify_form.treasury_challan_code') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    type="number"
                                                    id="treasury_challan_code"
                                                    v-model="data.treasury_challan_code"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Treasury Deposited Code" vid="treasury_deposited_amount" rules="required">
                                        <b-form-group class="row" label-cols-sm="6" label-for="treasury_deposited_amount" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('certify_form.treasury_deposited_amount') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                v-model="data.treasury_deposited_amount"
                                                id="treasury_deposited_amount"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Treasury Challan Number" vid="treasury_challan_number" rules="required">
                                        <b-form-group class="row" label-cols-sm="6" label-for="treasury_challan_number" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('certify_form.treasury_challan_number') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                v-model="data.treasury_challan_number"
                                                id="treasury_challan_number"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Treasury Challan Date" vid="treasury_challan_date" rules="required">
                                            <b-form-group class="row" label-cols-sm="6" label-for="treasury_challan_date" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('certify_form.treasury_challan_date') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    class="date-picker"
                                                    v-model="data.treasury_challan_date"
                                                    id="treasury_challan_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Treasury Challan File" vid="treasury_challan_file" rules="required">
                                            <b-form-group class="row" label-cols-sm="6" label-for="treasury_challan_file" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.treasury_challan_file') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-file
                                                @change="onTreasuryChange"
                                                id="treasury_challan_file"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-file>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Bank Name (En)" vid="bank_name" rules="required">
                                            <b-form-group class="row" label-cols-sm="6" label-for="bank_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.bank_name') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="data.bank_name"
                                                id="bank_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Bank Name (Bn)" vid="bank_name_bn" rules="required">
                                            <b-form-group class="row" label-cols-sm="6" label-for="bank_name_bn" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.bank_name_bn') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="data.bank_name_bn"
                                                id="bank_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Branch Name (En)" vid="branch_name" rules="required">
                                            <b-form-group class="row" label-cols-sm="6" label-for="branch_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.branch_name') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="data.branch_name"
                                                id="branch_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Branch Name (Bn)" vid="branch_name_bn" rules="required">
                                            <b-form-group class="row" label-cols-sm="6" label-for="branch_name_bn" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.branch_name_bn') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="data.branch_name_bn"
                                                id="branch_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row style="
                    display: flex;
                    flex-wrap: wrap;"
                    v-show="data.payment_status === 2"
                >
                    <b-col lg="12" sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="text-dark"><i class="ri-edit-line"></i> {{ $t('certify_form.ekpay') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row style="">
                                    <b-col md="6" >
                                        <ValidationProvider name="Deposited Amount" vid="ekpay_deposited_amount" rules="required">
                                            <b-form-group class="row" label-cols-sm="6" label-for="ekpay_deposited_amount" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('certify_form.ekpay_deposited_amount') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                v-model="data.ekpay_deposited_amount"
                                                id="ekpay_deposited_amount"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Ekpay Transaction Number" vid="ekpay_transaction_number" rules="required">
                                            <b-form-group class="row" label-cols-sm="6" label-for="ekpay_transaction_number" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('certify_form.ekpay_transaction_number') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    type="number"
                                                    v-model="data.ekpay_transaction_number"
                                                    id="ekpay_transaction_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" >
                                        <ValidationProvider name="Ek Pay Transaction Date" vid="ekpay_transaction_date" rules="required">
                                            <b-form-group class="row" label-cols-sm="6" label-for="ekpay_transaction_date" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('certify_form.ekpay_transaction_date') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    class="date-picker"
                                                    v-model="data.ekpay_transaction_date"
                                                    id="ekpay_transaction_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
    </b-overlay>
</template>

<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { storePaymentInfo, getPaymentInfo } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatpickr from 'flatpickr'
export default {
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            loading: false,
            tmpTreasuryFile: [],
            data: {
                draft: 1,
                payment_status: 1,
                treasury_challan_code: '',
                treasury_deposited_amount: '',
                treasury_challan_number: '',
                treasury_challan_date: '',
                treasury_challan_file: '',
                bank_name: '',
                bank_name_bn: '',
                branch_name: '',
                branch_name_bn: '',
                ekpay_deposited_amount: '',
                ekpay_transaction_number: '',
                ekpay_transaction_date: ''
            },
            options: [
                {
                    value: 1,
                    text: 'Bank'
                },
                {
                    value: 2,
                    text: 'ekPay'
                }
            ]
        }
    },
    created () {
        this.loading = true
        this.getPaymentInfo()
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    },
    methods: {
        async getPaymentInfo () {
            const result = await RestApi.getData(agriResearchServiceBaseUrl, getPaymentInfo, { user_id: this.$store.state.Auth.authUser.id })
            if (result.success) {
                this.data = { ...this.data, ...result.data.payment, table_id: result.data.id }
            }
            this.loading = false
        },
        async paymentSubmit (isNext = 1) {
            this.loading = true
            let mainForm = {}
            if (!isNext) {
                this.data.draft = isNext
            }
            mainForm = { ...this.data, user_id: this.$store.state.Auth.authUser.id }

            var formData = new FormData()
            Object.keys(mainForm).map(key => {
                if (key === 'treasury_challan_file') {
                    formData.append(key, this.tmpTreasuryFile)
                } else {
                    formData.append(key, mainForm[key])
                }
            })

            let result = null
            result = await RestApi.postData(agriResearchServiceBaseUrl, storePaymentInfo, formData)
            if (result.success) {
                this.$toast.success({
                title: 'Success',
                message: this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })
                this.loading = false
                return result
            } else {
                this.$toast.error({
                title: 'Error',
                message: this.$t('globalTrans.form_error_msg'),
                color: '#D6E09B'
                })
                this.$refs.form.setErrors(result.errors)
            }
            this.loading = false
        },
        onTreasuryChange (e) {
            this.tmpTreasuryFile = e.target.files[0]
        }
    }
}
</script>

<style>

</style>
